#i2i-lightbox {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: opacity .2s ease;
    z-index:9;
}

#i2i-lightbox.small {
    top: 100%
}

#i2i-lightbox.open {
    display: block;
    -webkit-animation-name: fadeIn;
    -moz-animation-name: fadeIn;
    -o-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: .3s;
    -moz-animation-duration: .3s;
    -o-animation-duration: .3s;
    animation-duration: .3s
}

@keyframes fadeIn {
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

#i2i-lightbox-background {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75)
}

#i2i-lightbox-modal {
    position: absolute;
    /*padding: 2em;*/
    padding: 0;
    right: 50%;
    bottom: 50%;
    transform: translate(50%, 50%);
    background: #fff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    width: 90%;
    height: 90%;
    max-width: calc(100vw - 3.5em);
    max-height: calc(100vh - 3.5em);
    transition: all .2s ease
}

#i2i-lightbox.small #i2i-lightbox-modal {
    position: fixed;
    right: 2%;
    bottom: 2%;
    transform: translate(0)
}

#i2i-lightbox-modal-content {
    border: 0;
    width: 100%;
    height: 100%;
    background: #000000;
}

#i2i-lightbox-modal-close {
    font-family: Arial, sans-serif;
    color: #FFFFFF; /* Default: #fff */
    font-weight: bold;
    font-size: 1.5em;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5em;
    height: 1.5em;
    top: .25rem;
    right: .25rem;
    /* Moves close button to corner of box */
    /*transform: translate(50%, -50%);*/
    /*background-color: #000;*/
    border: .125em solid #fff;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    cursor: pointer
}

#i2i-lightbox-pip-button {
    display: none;
    width: 3em;
    position: absolute;
    cursor: pointer;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease
}

#i2i-lightbox-pip-button.touch {
    opacity: .2
}

#i2i-lightbox-pip-button svg {
    fill: #fff
}

#i2i-lightbox.small #i2i-lightbox-pip-button {
    display: block
}

#i2i-lightbox-modal:hover #i2i-lightbox-pip-button {
    opacity: 1
}
